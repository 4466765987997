.App {
  text-align: center;
}

.noteText {
  color: #696969;
  font-size: 15px;
  padding-top: 50px;
  padding-bottom: 20px;
}

.headerText {
  font-size: 6em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selectContainer {
  width: 100vw;
  height: 50px;
}

.countrySelect {
  width: 200px;
  float: right;
  margin: 10px;
}

.mainText {
  font-size: 6em;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: auto;
  margin-left: auto;
  width: 200px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.mainText:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.secondaryText {
  padding-top: 10px;
  font-size: 2em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 600px) {

  .headerText {
    font-size: 4em;
  }

  .mainText {
    font-size: 3em;
  }

  .secondaryText {
    font-size: 1.5em;
  }

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
